.balance-cell {
  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: baseline;
    .statuses {
      color: white;
      font-weight: bold;
      margin-left: auto;
      i {
        margin-right: 3px;
      }
      .badge {
        display: inline-block;
        font-size: 7px;
        vertical-align: top;
        margin-left: -8px;
        margin-top: -3px;
        border-radius: 50%;
        padding-left: 3px;
        padding-right: 3px;
        background-color: white;
        border: 1px solid gray;
        color: black;
      }
    }
  }
}
