.navbar-brand {
    font-size: 25px;

}
.logo {
    margin-right: 10px;
}
.navbar {
    box-shadow: 0 0 1px rgba(0,0,0,.25);
    z-index: 10;
}

.has-pointer {
    cursor: pointer;
}