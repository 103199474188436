* {
  font-family: "Rubik", sans-serif;
}

#main-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: "Rubik", sans-serif;

  .wrapper {
    flex: 1;
  }

  #main-footer {
    margin-top: 20px;
    text-align: center;
    border-top: 1px dashed gray;
  }

  .content {
    .title {
      text-align: center;
      margin-top: 1.5rem;
    }

    .actions {
      margin-bottom: 1.5em;
      text-align: right;
    }
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.is-clearfix {
  clear: both;
}

.has-background-warning {
  background-color: #ffe08a !important;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  top: 220px;
  bottom: 40px;
  left: 30px;
  right: 30px;
  position: fixed;
  background-color: white;

  .status-key {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 5px;
    width: 100%;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    background-color: white;

    th {
      position: sticky;
      top: 25px;
      z-index: 2;
      background-color: white;
    }

    td:first-child,
    th:first-child {
      left: 0;
      position: sticky;
      z-index: 1;
      background-color: white;
    }

    th:first-child {
      z-index: 3;
    }
  }
}

.form-actions {
  .button {
    margin-right: 15px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.position-absolute {
  position: absolute;
}

.pointer,
.cursor,
.is-cursor,
.is-pointer {
  cursor: pointer;
}

.is-hidden {
  display: none;
}

.overflow-inherit {
  overflow: inherit;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.control.has-icons-right {
  .icon.cursor {
    z-index: 5;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.react-tooltip, .react-tooltip-arrow {
  background-color: red;
}