.box .image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

.response-input {
  margin-top: 10px;
  margin-bottom: 20px;
}

.report-messages-container {
  font-size: 14px;
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: row;
  // justify-content: left;

  .report-message {
    display: inline-block;
    width: 300px;
    margin: 0 20px 20px 0;
    // position: relative;
    // padding-bottom: 350px;

    .admin-buttons {
      position: absolute;
      top: 0;
      right: 0;
      .icon {
      }
    }

    // .bottom {
    //   position: absolute;
    //   bottom: 0;
    // }
  }

  margin-bottom: 20px;
}