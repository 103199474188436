.itemListing {
  table th {
    white-space: nowrap;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }
  table tr {
  }
  table td {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .action {
    cursor: pointer;
  }

  .x-scroll-container {
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    table {
      padding: 0;
      margin: 0;
    }
  }
}

.file-name {
  border-width: 1px;
}
.is-outstanding {
  background-color: hsl(48, 100%, 67%);
}

.is-changed {
  background-color: hsl(277, 38%, 67%);
}
.is-response-provided,
.is-message-provided {
  background-color: hsl(204, 86%, 53%);
}
.is-deadline-passed {
  background-color: hsl(28, 98%, 50%);
}
.is-extended-deadline-passed {
  background-color: hsl(348, 100%, 61%);
}
.is-paid {
  background-color: hsl(141, 71%, 48%);
}

.status-key {
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
 
  .key-specifier {
    display: inline-block;
    vertical-align: middle;
    padding: 3px 5px 3px 5px;
  }
  label {
    padding: 3px 5px 3px 5px;
    vertical-align: middle;
    display: inline-block;
  }
  input {
    vertical-align: -3px;
    display: inline-block;
  }
  margin-bottom: 5px;
}
